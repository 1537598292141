<template>
    <component
        :is="tag"
        v-on="$listeners"
        class="z-button"
        :class="classObject"
        :href="tag === 'a' ? href : false"
        :disabled="disabled"
    >
        <div v-if="hasLeftSlot" class="z-button__icon z-button__icon--align-left">
            <slot name="left" />
        </div>
        <span><slot /></span>
        <div v-if="hasRightSlot" class="z-button__icon z-button__icon--align-right">
            <slot name="right" />
        </div>
    </component>
</template>

<script>
export default {
    name: 'z-button',
    props: {
        tag: {
            type: String,
            default: 'button'
        },
        kind: {
            type: String,
            validator: prop => [
                'primary',
                'secondary',
                'bordered'
            ].includes(prop),
            default: 'primary'
        },
        onlyIcon: Boolean,
        loading: {
            type: Boolean,
            default: false
        },
        href: String,
        size: {
            type: String,
            validator: prop => ['s', 'm', 'l'].includes(prop),
            default: 'm'
        },
        disabled: Boolean
    },
    computed: {
        hasLeftSlot () {
            return !!this.$slots['left']
        },
        hasRightSlot () {
            return !!this.$slots['right']
        },
        classObject () {
            let arrClass = [
                { 'z-button--only-icon': this.onlyIcon },
                `z-button--kind-${this.kind}`,
                `z-button--size-${this.size}`,
                { 'z-button--loading': this.loading }
            ]

            return arrClass
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
