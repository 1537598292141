<template>
    <nav
        class="navigation-mobile"
        aria-label="Main navigation"
        :class="{'navigation-mobile--index': isIndex}"
    >
        <a
            class="navigation-mobile__burger"
            href="javascript:void(0);"
            :class="{'is-active': burgerMenu}"
            @click="toggleDropdown()"
            title=""
        >
            <z-icon
                class="navigation-mobile__burger--open"
                name="tools/burger"
            />
            <z-icon
                class="navigation-mobile__burger--close"
                name="tools/close"
            />
        </a>
        <div class="navigation-mobile__dropdown nm-dropdown" v-if="burgerMenu">
            <navigation-mobile-body/>
            <navigation-mobile-footer :static-links="staticLinks"/>
        </div>
    </nav>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import NavigationMobileBody from './components/NavigationMobileBody.vue'
import NavigationMobileFooter from './components/NavigationMobileFooter.vue'

export default {
    name: 'navigation-mobile',
    components: {
        NavigationMobileFooter,
        NavigationMobileBody
    },
    props: {
        isIndex: Boolean,
        staticLinks: {
            type: Array,
            default: null
        }
    },
    computed: {
        ...mapGetters(['burgerMenu'])
    },
    watch: {
        burgerMenu (next, prev) {
            let header = document.querySelector('.header')

            header.classList.toggle('is-open', next && header)
            document.body.classList.toggle('is-disabled', next && header)
            document.body.classList.toggle('mobile-nav-opened', next && header)
        }
    },
    methods: {
        ...mapActions(['ToggleBurgerMenuAction']),
        toggleDropdown () {
            this.ToggleBurgerMenuAction()
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
