/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/big/webm': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.933 4.667H30.6a1 1 0 01.707.293l1.807 1.807h-.011l.472.472L45.1 18.765v-.01l1.807 1.806a1 1 0 01.293.707V46.9a5.6 5.6 0 01-5.6 5.6H14.933a5.6 5.6 0 01-5.6-5.6V10.267a5.6 5.6 0 015.6-5.6zM45.101 20.94H32.875a1.4 1.4 0 01-1.4-1.4V6.767H14.933a3.5 3.5 0 00-3.5 3.5V46.9a3.5 3.5 0 003.5 3.5h26.668a3.5 3.5 0 003.5-3.5V20.941zm-11.526-2.1v-8.633l8.633 8.633h-8.633z" _fill="#1092DC"/><path pid="1" d="M16.142 36.12l1.252-6.107h.773l-.032 1.53-1.353 6.29h-.811l.171-1.713zm-.794-6.107l.993 6.064.043 1.756h-.854l-1.477-7.82h1.295zm4.178 6.042l.983-6.042h1.29l-1.472 7.82h-.854l.053-1.778zm-1.047-6.042l1.24 6.123.162 1.697h-.811l-1.322-6.295-.032-1.525h.763zm8.438 6.73v1.09h-3.394v-1.09h3.394zm-3.05-6.73v7.82H22.56v-7.82h1.305zm2.594 3.255v1.074h-2.938v-1.074h2.938zm.424-3.255v1.096h-3.362v-1.096h3.362zm3.405 4.297h-1.595l-.005-1h1.412c.219 0 .405-.042.559-.128a.828.828 0 00.35-.376 1.32 1.32 0 00.123-.596c0-.258-.036-.468-.108-.629a.693.693 0 00-.338-.36 1.297 1.297 0 00-.58-.112h-1.026v6.724h-1.305v-7.82h2.33c.366 0 .692.043.978.129.29.082.536.21.736.381.2.172.353.392.457.66.107.266.16.58.16.946 0 .319-.067.613-.203.881a1.687 1.687 0 01-.607.655c-.265.169-.595.269-.988.301l-.35.344zm-.048 3.523h-1.977l.602-1.09h1.375c.233 0 .424-.05.575-.15.15-.1.263-.24.338-.42.075-.178.113-.388.113-.628 0-.25-.032-.469-.097-.655a.843.843 0 00-.311-.43c-.143-.1-.333-.15-.57-.15h-1.316l.011-1h1.681l.301.382c.376.007.682.1.919.28.236.175.41.402.52.682.112.279.167.578.167.896 0 .498-.093.917-.28 1.257a1.843 1.843 0 01-.8.768c-.343.172-.76.258-1.25.258zm3.862-7.82h1.187l1.8 5.817 1.793-5.817h1.101l-2.406 7.82h-.983l-2.492-7.82zm-.446 0h1.096l.177 4.979v2.841h-1.273v-7.82zm5.758 0h1.101v7.82h-1.273v-2.841l.172-4.98z" _fill="#1092DC"/>'
  }
})
