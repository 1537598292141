/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/big/jpeg': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.933 4.667H30.6a1 1 0 01.707.293l1.807 1.807h-.011l.472.472L45.1 18.765v-.01l1.807 1.806a1 1 0 01.293.707V46.9a5.6 5.6 0 01-5.6 5.6H14.933a5.6 5.6 0 01-5.6-5.6V10.267a5.6 5.6 0 015.6-5.6zM45.101 20.94H32.875a1.4 1.4 0 01-1.4-1.4V6.767H14.933a3.5 3.5 0 00-3.5 3.5V46.9a3.5 3.5 0 003.5 3.5h26.668a3.5 3.5 0 003.5-3.5V20.941zm-11.526-2.1v-8.633l8.633 8.633h-8.633z" _fill="#1092DC"/><path pid="1" d="M15.45 37.932l-.1-1.155.782-.044c.652-.044.979-.403.979-1.078v-5.577h1.408v5.566c0 1.394-.722 2.142-2.167 2.244l-.902.044zm4.548-.099v-7.755h3.366c.85 0 1.507.213 1.969.638.462.426.693 1.02.693 1.782 0 .763-.231 1.36-.693 1.793-.462.426-1.118.638-1.969.638h-1.958v2.904h-1.408zm1.408-4.015h1.738c1.012 0 1.518-.44 1.518-1.32 0-.872-.506-1.309-1.518-1.309h-1.738v2.63zm5.548 4.015v-7.755h5.159V31.2h-3.806v2.134h3.564v1.122h-3.564v2.255h3.806v1.122h-5.16zm10.062.11c-.858 0-1.584-.161-2.178-.484a3.237 3.237 0 01-1.343-1.375c-.307-.594-.462-1.298-.462-2.112 0-.821.158-1.529.474-2.123a3.272 3.272 0 011.364-1.386c.593-.33 1.305-.495 2.133-.495.543 0 1.053.08 1.53.242.476.154.872.37 1.188.65l-.474 1.1a3.436 3.436 0 00-1.055-.584 3.808 3.808 0 00-1.188-.176c-.822 0-1.445.239-1.87.715-.418.477-.627 1.163-.627 2.057 0 .902.216 1.588.648 2.057.433.47 1.075.704 1.925.704.242 0 .488-.018.737-.055.257-.044.51-.106.76-.187v-1.804h-1.783v-1.045h2.96v3.73c-.36.175-.785.315-1.277.417a7.141 7.141 0 01-1.462.154z" _fill="#1092DC"/>'
  }
})
