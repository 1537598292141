<template>
    <div :class="['mosaic-block-item', { 'mosaic-block-item--image-first': imageFirst }]">
        <div class="mosaic-block-item__content">
            <slot />
        </div>
        <div class="mosaic-block-item__image" :style="`background-image: url(${image});`"></div>
    </div>
</template>

<script>
export default {
    name: 'mosaic-block-item',
    props: {
        image: String,
        imageFirst: Boolean
    }
}
</script>

<style lang="scss">
.mosaic-block-item {
    $parent: &;
    display: flex;
    margin-top: 0;

    @include breakpoint(v-tablet) {
        flex-direction: column;

        & + & {
            margin-top: $token-spacers-xs;
        }
    }

    &__content,
    &__image {
        flex-basis: 50%;
        max-width: 50%;
        margin-top: 0;

        @include breakpoint(v-tablet) {
            width: 100%;
            max-width: 100%;
            flex-basis: 100%;
        }
    }

    &__content {
        padding: 48px 60px;

        @include breakpoint (v-tablet) {
            padding: $token-spacers-s $token-spacers-2-xs;
            order: 1;
        }
    }

    &__image {
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;

        @include breakpoint (v-tablet) {
            order: 0;
        }

        &:after {
            content: '';
            display: block;
            padding-top: 85%;

            @include breakpoint(v-tablet) {
                padding-top: 60%;
            }
        }
    }

    &--image-first {
        #{$parent}__image {
            order: 0;
        }

        #{$parent}__content {
            order: 1;
        }
    }
}
</style>
