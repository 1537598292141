<template>
    <div class="z-select-wrapper">
        <div
            :class="[
                'z-select',
                {
                    'z-select--filter': isFilter,
                    'z-select--clearable': isClearable,
                    'is-opened': opened,
                    'is-errored': isValid === false,
                    'is-valid': isValid && !isFilter,
                    'is-filled': selected.text,
                    'is-required': required,
                    'is-disabled': !data && !data.length
                }
            ]"
        >
            <div class="z-select__container" @click.self="opened = !opened" tabindex="0">
                <span
                    class="z-select__placeholder"
                    v-if="placeholder"
                    v-html="placeholder"
                ></span>
                <span
                    class="z-select__selected"
                    v-if="selected.text.length"
                    v-html="selected.text"
                ></span>
                <span
                    class="z-select__clear"
                    v-if="selected.text.length && isClearable"
                    @click.self="removeSelected"
                ></span>
                <span class="z-select__arrow"></span>
            </div>
            <div class="z-select__dropdown" v-if="opened">
                <ul
                    class="z-select__options"
                    v-for="(group, groupIndex) in groupedOptions"
                    :key="groupIndex"
                >
                    <li>
                        <span
                            class="z-select__caption"
                            v-html="group.name"
                            v-if="group.name"
                        ></span>
                        <ul
                            v-if="group.items && group.items.length"
                            :class="{ 'u-left-margin--one' : groupIndex !== 'nogroup' }"
                        >
                            <li
                                class="z-select__option"
                                v-for="option in group.items"
                                :class="buildClass(option)"
                                @click="changeSelected(option)"
                                :key="option.id"
                            >
                                <vue-raw :raw="option.text" />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <span
            :class="['z-select__error', errorClass]"
            v-if="required && error && !selected.id.length"
        >
            {{ text.error[$root.lang] }}
        </span>
    </div>
</template>

<script>
export default {
    name: 'z-select',
    props: {
        data: {
            type: Array,
            default: () => []
        },
        name: String,
        placeholder: String,
        required: Boolean,
        value: [String, Number],
        errorClass: {
            type: String,
            default: ''
        },
        clearable: {
            type: Boolean,
            default: false
        },
        isFilter: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            selected: {
                id: '',
                text: ''
            },
            opened: false,
            error: false,
            duration: 300,
            options: this.data,
            isValid: null,
            text: {
                error: {
                    ru: 'Поле обязательно для заполнения',
                    en: 'Required field',
                    cn: '填项目'
                }
            }
        }
    },
    computed: {
        groupedOptions () {
            let result = {}
            this.options.forEach(item => {
                if (item.group) {
                    if (!result[item.group]) result[item.group] = {}
                    if (!result[item.group].items) result[item.group].items = []
                    result[item.group].name = item.group
                    result[item.group].items.push(item)
                } else {
                    if (!result.nogroup) result.nogroup = {}
                    if (!result.nogroup.items) result.nogroup.items = []
                    result.nogroup.name = ''
                    result.nogroup.items.push(item)
                }
            })

            return result
        },
        isClearable () {
            if (this.isFilter) return true
            return this.clearable
        }
    },
    mounted () {
        this.onLoad()

        // this.$parent.$parent.$on('clear', name => {
        //     if (this.name === name) this.removeSelected()
        // })

        document.addEventListener('click', e => this.hideDropdown(e))
    },
    beforeDestroy () {
        // this.$parent.$parent.$off('clear', name => {
        //     if (this.name === name) this.removeSelected()
        // })

        document.removeEventListener('click', e => this.hideDropdown(e))
    },
    watch: {
        // data (array) {
        //     this.options = array
        // },
        value (value) {
            // нужно вернуться и сделать нормально, переработать работу селекта, сделать нормальный v-model и унифицировать отдачу value со остальными элементами форм
            if (value) {
                let option = this.options.find(item => item.id === value)
                if (option) {
                    this.selected = {
                        id: option.id,
                        text: option.text
                    }

                    return
                }
            }

            this.selected = {
                id: '',
                text: ''
            }
        }
    },
    methods: {
        onLoad () {
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].selected === true) this.initSelected(this.options[i])
            }
        },
        buildClass (option) {
            return {
                'is-selected': option.selected,
                'is-disabled': option.disabled
            }
        },
        hideDropdown (e) {
            let isOutside = this.$el !== e.target && !this.$el.contains(e.target)
            // eslint-disable-next-line no-unused-expressions
            isOutside ? this.opened = false : false
        },
        changeSelected (option) {
            option.selected ? this.removeSelected() : this.addSelected(option)
            this.opened = false
        },
        initSelected (option) {
            this.selected.id = option.id
            this.selected.text = option.text

            this.options = this.options.map(item => {
                if (item.id === this.selected.id) {
                    return { ...item, selected: true }
                }
                return { ...item, selected: false }
            })
        },
        addSelected (option) {
            this.selected.id = option.id
            this.selected.text = option.text

            this.options = this.options.map(item => {
                if (item.id === this.selected.id) {
                    return { ...item, selected: true }
                }
                return { ...item, selected: false }
            })

            this.send(this.selected.id)
        },
        removeSelected () {
            this.opened = false
            this.selected.id = ''
            this.selected.text = ''

            this.options = this.options.map(item => {
                return { ...item, selected: false }
            })

            this.send('')
        },
        send (value) {
            this.$nextTick(() => {
                this.$emit('input', value)
                this.$emit('change', value)
            })
            this.validate()
        },
        validate () {
            if (this.required) {
                if (this.selected.id.toString().length) {
                    this.isValid = true
                    this.error = ''
                } else {
                    this.isValid = false
                    this.error = true
                }
            } else {
                if (this.selected.id.toString().length) {
                    this.isValid = true
                    this.error = ''
                } else {
                    this.isValid = null
                    this.error = false
                }
            }
        }
    }
}
</script>

<style lang="scss">
$clearColorDefault: '%23A1A7B5';
$clearColorHover: '%23232323';

.z-select-wrapper {
    font-size: 16px;
    font-weight: 400;
}

.z-select {
    $parent: &;

    font-size: var(--textSize);
    width: 100%;
    position: relative;
    border: var(--formBorderWidth) solid var(--formBorderColorDefault);
    border-radius: var(--borderRadius);
    user-select: none;
    height: var(--fieldHeightM);

    &__caption {
        font-weight: 600;
        padding: 10px;
        display: block;
        color: $token-colors-black-30;
        font-weight: 700;
        font-size: var(--textSize);
    }

    &__container {
        width: 100%;
        position: relative;
        cursor: pointer;
        padding: var(--fieldPaddingM);
        height: 100%;
    }

    &__selected {
        display: block;
        margin-bottom: .5em;
        font-size: 1em;
        line-height: 1.4;
        color: var(--formTextColorDefault);
        pointer-events: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 60px;
        margin-top: 0;
    }

    &__placeholder {
        display: block;
        font-size: 1em;
        line-height: 1.4;
        color: var(--formPlaceholderColorDefault);
        pointer-events: none;
        position: absolute;
        top: 50%;
        transform: translateY(var(--placeholderTranslateY)) scale(1);
        left: 16px;
        padding: 0 4px;
    }

    &__clear {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-position: 50% 50%;
        background-size: contain;
        background-repeat: no-repeat;
        cursor: pointer;
        width: var(--iconSize);
        height: var(--iconSize);
        margin-top: 0;
        right: calc(var(--iconSize) + 16px + 8px);
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.0493 4.79926C16.2836 4.56495 16.2836 4.18505 16.0493 3.95074C15.8149 3.71642 15.4351 3.71642 15.2007 3.95074L10 9.15147L4.79926 3.95074C4.56495 3.71642 4.18505 3.71642 3.95074 3.95074C3.71642 4.18505 3.71642 4.56495 3.95074 4.79926L9.15147 10L3.95074 15.2007C3.71642 15.4351 3.71642 15.8149 3.95074 16.0493C4.18505 16.2836 4.56495 16.2836 4.79926 16.0493L10 10.8485L15.2007 16.0493C15.4351 16.2836 15.8149 16.2836 16.0493 16.0493C16.2836 15.8149 16.2836 15.4351 16.0493 15.2007L10.8485 10L16.0493 4.79926Z' fill='#{$clearColorDefault}'/%3E%3C/svg%3E%0A");

        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.0493 4.79926C16.2836 4.56495 16.2836 4.18505 16.0493 3.95074C15.8149 3.71642 15.4351 3.71642 15.2007 3.95074L10 9.15147L4.79926 3.95074C4.56495 3.71642 4.18505 3.71642 3.95074 3.95074C3.71642 4.18505 3.71642 4.56495 3.95074 4.79926L9.15147 10L3.95074 15.2007C3.71642 15.4351 3.71642 15.8149 3.95074 16.0493C4.18505 16.2836 4.56495 16.2836 4.79926 16.0493L10 10.8485L15.2007 16.0493C15.4351 16.2836 15.8149 16.2836 16.0493 16.0493C16.2836 15.8149 16.2836 15.4351 16.0493 15.2007L10.8485 10L16.0493 4.79926Z' fill='#{$clearColorHover}'/%3E%3C/svg%3E%0A");
        }
    }

    &__arrow {
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 9L12 15L18 9' stroke='#{$clearColorDefault}' stroke-width='1.5'/%3E%3C/svg%3E%0A");
        top: 50%;
        right: 16px;
        border: none;
        width: var(--iconSize);
        height: var(--iconSize);
        background-size: 24px auto;
        background-position: 50% 50%;
        transform: translateY(-50%);
        pointer-events: none;
        margin-top: 0;
        background-repeat: no-repeat;

        .is-opened & {
            transform: rotate(180deg) translateY(50%);
        }
    }

    &__dropdown {
        width: calc(100% + (var(--formBorderWidth)*2));
        position: absolute;
        left: calc(var(--formBorderWidth) * -1);
        z-index: 3;
        background-color: var(--formBgColor5);
        top: calc(100% + var(--formBorderWidth));
        // border: var(--formBorderWidth) solid var(--formBorderColorFocus);
        // border-top: none;
        max-height: 220px;
        overflow: auto;
        padding: 8px;

        &::-webkit-scrollbar {
            width: 8px !important;
            background: var(--formBgColor5);
        }

        &::-webkit-scrollbar-thumb {
            background: var(--formBgColor30);
            border-radius: 40px;
        }
    }

    &__options {
        margin: 0;
        margin-top: -4px;

        & + & {
            margin-top: 8px;
        }
    }

    &__option {
        font-size: var(--textSize);
        cursor: pointer;
        position: relative;
        padding: 6px 12px;
        color: var(--formTextColorDefault);
        transition: background 0.2s ease-in;
        margin-top: 4px;
        line-height: 1.4;
        border-radius: var(--borderRadius);

        &:hover {
            background: var(--formBgColor10);
        }

        &.is-selected {
            background: var(--formBgColor20);
        }

        &.is-disabled {
            opacity: .5;
            pointer-events: none;
        }

        .z-tooltip {
            width: 100%;
        }

        .tippy-tooltip {
            max-width: calc(100% - 52px) !important;
        }
    }

    &__error {
        font-size: var(--errorTextSize);
        color: var(--errorTextColor);
        position: relative;
    }

    // filled state
    &.is-filled {
        border-color: var(--formBorderColorFilled);

        #{$parent}__placeholder {
            width: auto;
            transform: translateY(var(--placeholderFilledTranslateY));
            transition: all 0.2s ease-in;
            font-size: var(--filledPlaceholderTextSize);
            line-height: 1.2;
            background-color: var(--placeholderBg);
        }
    }

    //hover state
    &:hover {
        border-color: var(--formBorderColorHover);
    }

    // errored state
    &.is-errored {
        border-color: var(--formBorderColorError);
    }

    // valid state
    &.is-valid {
        border-color: var(--formBorderColorValid);
    }

    // opened
    &.is-opened {
        border-color: var(--formBorderColorFocus);

        #{$parent}__arrow {
            transition: all 0.2s ease-in;
        }
    }

    &--filter {
        // filled state
        &.is-filled {
            border-color: var(--formBorderColorFilledAccent);

            #{$parent}__dropdown {
                border-color: var(--formBorderColorFilledAccent);
            }
        }
    }

    &.is-required {
        .z-select__placeholder {
            &:after {
                content: '*';
                color: var(--errorTextColor);
                margin-left: 4px;
            }
        }
    }

    &.is-disabled {
        border: var(--formBorderColorDisabled);
        background: var(--formbgDisabled);
        pointer-events: none;

        .z-select__placeholder {
            color: var(--formPlaceholderColorDisabled);
        }
    }
}

</style>
