<template>
    <div
        :class="['z-card', classObject]"
        @click="$emit('click')"
    >
        <div
            class="z-card__image"
            v-if="image"
            :style="`background-image: url(${image})`"
        ></div>
        <div class="z-card__body">
            <header
                class="z-card__header"
                v-if="this.$slots['top']"
            >
                <slot name="top" />
            </header>
            <div class="z-card__main">
                <span
                    class="z-card__title"
                    v-if="this.$slots['title']"
                >
                    <slot name="title" />
                </span>
                <div
                    v-if="this.$slots['content']"
                    class="z-card__content"
                >
                    <slot name="content"></slot>
                </div>
            </div>
            <footer
                class="z-card__footer"
                v-if="this.$slots['bottom'] || this.$slots['bottom-left'] || this.$slots['bottom-right']"
            >
                <div class="z-card__bottom"
                     v-if="this.$slots['bottom']"
                >
                    <slot name="bottom" />
                </div>
                <div
                    class="z-card__bottom-left"
                    v-if="this.$slots['bottom-left']"
                >
                    <slot name="bottom-left" />
                </div>
                <div
                    class="z-card__bottom-right"
                    v-if="this.$slots['bottom-right']"
                >
                    <slot name="bottom-right" />
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
export default {
    name: 'z-card',
    props: {
        image: {
            type: String,
            default: ''
        },
        theme: {
            type: String,
            validator: prop => ['white', 'gray'].includes(prop)
        },
        minHeightAuto: Boolean
    },
    computed: {
        classObject () {
            return {
                [`z-card--theme-${this.theme}`]: this.theme,
                [`z-card--min-height-auto`]: this.minHeightAuto
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
