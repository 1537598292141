var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"z-input-file"},[_c('label',{staticClass:"z-input-file__label",class:{
            'is-disabled': _vm.disabled
        },attrs:{"for":_vm.name}},[_c('input',{ref:"file",attrs:{"id":_vm.name,"type":"file","name":_vm.name,"disabled":_vm.disabled,"multiple":_vm.multiple},on:{"change":function($event){return _vm.onChange($event.target)}}}),_vm._v(" "),_c('z-icon',{attrs:{"name":"tools/paperclip","width":"24","height":"24"}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.labelText)}})],1),_vm._v(" "),(_vm.files.length)?_c('z-filelist',{staticClass:"z-input-file__list"},_vm._l((_vm.files),function(file,index){return _c('z-filelist-item',{key:index,class:[
                'z-input-file__item',
                { 'is-error': !_vm.isFormatCorrect(file)}
            ],attrs:{"size":"l","icon":("file/big/" + (file.type)),"file-size":_vm.getFormatedSize(file.size)}},[_c('p',{staticClass:"z-input-file__item-name"},[_c('span',[_vm._v(_vm._s(file.name))]),_vm._v(" "),_c('z-icon',{attrs:{"name":"tools/close-small","width":"24","height":"24"},on:{"click":function($event){return _vm.remove(file, index)}}})],1)])}),1):_vm._e(),_vm._v(" "),(_vm.error && !_vm.isValid)?_c('span',{class:[
            'z-input-file__error',
            _vm.errorClass
        ],domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }