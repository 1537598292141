var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
        'z-date-picker',
        {
            'z-date-picker--filter': _vm.isFilter,
            'is-disabled': _vm.disabled,
            'is-filled': _vm.isFilled,
            'is-focused': _vm.isFocused,
            'is-errored': _vm.isValid === false,
            'is-required': _vm.required,
            'is-valid': _vm.isValid && !_vm.isFilter
        }
    ]},[_c('date-picker',_vm._b({key:_vm.lang,ref:"picker",attrs:{"type":_vm.type,"lang":_vm.locale,"range":_vm.range,"format":_vm.format,"title-format":_vm.format,"clearable":_vm.isClearable,"value-type":_vm.valueType,"disabled-date":_vm.disabledDate,"range-separator":" — ","popup-class":("z-date-picker-popup " + (_vm.range ? 'z-date-picker-popup--range' : ''))},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"change":_vm.change,"clear":function($event){return _vm.onClear()}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'date-picker',_vm.$attrs,false)),_vm._v(" "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.placeholder),expression:"placeholder"}],staticClass:"z-date-picker__placeholder",domProps:{"innerHTML":_vm._s(_vm.placeholder)}}),_vm._v(" "),(_vm.error && !_vm.isValid)?_c('span',{class:[
        'z-date-picker__error',
        _vm.errorClass
      ],domProps:{"innerHTML":_vm._s(_vm.error)}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }