/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'rsp': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M25.38 9.361l-.362-4.16c.377.317.733.76 1.033 1.297.424.759.719 1.68.799 2.631.16 1.893-.525 3.874-2.703 5.004-.292.151-.891.279-1.623.298-.72.02-1.503-.068-2.14-.294a.515.515 0 00-.552.137l-.676.737a.4.4 0 00.59.54l.55-.6c.715.222 1.524.3 2.249.28.774-.02 1.518-.153 1.97-.388 2.523-1.309 3.313-3.637 3.132-5.781a7.425 7.425 0 00-.897-2.954c-.476-.852-1.114-1.56-1.843-1.945-.384-.203-.771.126-.74.495l.401 4.606-2.427 1.68-2.218-1.479V4.639c0-.41-.456-.65-.794-.426-1.73 1.145-2.512 2.623-2.736 4.057-.21 1.335.067 2.613.473 3.533l-.432.509a.4.4 0 10.61.517l.55-.647a.513.513 0 00.071-.555c-.39-.81-.675-2-.482-3.233.171-1.092.719-2.233 1.94-3.191v4.417c0 .172.085.332.228.427l2.509 1.672a.513.513 0 00.576-.005l2.725-1.886a.513.513 0 00.219-.466zm-13.02 8.54a.4.4 0 00-.54-.59l-6.912 6.334a2.492 2.492 0 103.487 3.558l6.31-6.61a.4.4 0 10-.58-.552l-6.308 6.61a1.692 1.692 0 11-2.368-2.416l6.912-6.334zM5.615 8.169a1.349 1.349 0 111.908-1.907l9.453 9.454-.49.49-.927.927-.49.49L5.614 8.17zm11.72 8.32l.373-.373h.452a.4.4 0 000-.8h-.452l-9.62-9.62a2.149 2.149 0 00-3.038 3.04l9.62 9.619v.452a.4.4 0 00.8 0v-.452l.372-.373 6.246 6.245v.715c0 .136.054.267.15.363l2.514 2.515c.2.2.525.2.726 0l1.695-1.695c.2-.2.2-.526 0-.726l-2.515-2.514a.513.513 0 00-.363-.15h-.714l-6.246-6.246zm5.402 7.257l-6.33-6.33.362-.361 6.33 6.33c.096.095.227.15.363.15h.715l2.227 2.227-1.289 1.289-2.227-2.228v-.714a.513.513 0 00-.15-.363zM6.542 6.624a.4.4 0 10-.566.566l8.964 8.964a.4.4 0 00.566-.566L6.542 6.624z" _fill="#81899D"/>'
  }
})
