<template>
    <div class="weather-widget" v-if="time && temp">
        <div class="weather-widget__wrapper">
            <div class="weather-widget__place">
                <z-icon name="contacts/mappin" width="16" height="16" />
                <span v-html="location"></span>
            </div>
            <div class="weather-widget__time">{{time}}</div>
        </div>
        <div class="weather-widget__value">{{temp}}</div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'weather-widget',
    props: {
        lat: [String, Number],
        lon: [String, Number],
        location: String
    },
    data () {
        return {
            time: null,
            temp: null
        }
    },
    created () {
        this.getData()
    },
    methods: {
        getData () {
            fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${this.lat}&lon=${this.lon}&appid=a120d365ae78ce5e210edfc0714208c4`)
                .then((response) => {
                    return response.json()
                })
                .then((data) => {
                    this.temp = Math.ceil(data.main.temp - 273.15) // перевод из Кельвинов в Цельсии
                    this.time = moment().utc().add(7, 'hours').format('dd HH:mm')
                })
                .catch(error => {
                    console.log(error)
                })
        }
    }
}
</script>

<style lang="scss">
.weather-widget {
    color: $token-colors-white;
    font-size: 15px;

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__place {
        font-weight: 700;
        padding-right: $token-spacers-2-xs;

        .z-icon {
            margin-right: 4px;

            path {
                fill: currentColor !important;
            }
        }
    }

    &__time {
        text-transform: capitalize;
        padding-left: $token-spacers-2-xs;
        border-left: 1px solid $token-colors-black-15;
    }

    &__value {
        font-size: 86px;
        font-weight: 700;
        margin-top: $token-spacers-3-xs;

        &:after {
            content: '\00B0 C';
            font-size: 48px;
            width: 100%;
            height: 100%;
            vertical-align: super;
            white-space: nowrap;
        }
    }
}
</style>
