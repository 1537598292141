<template>
    <div class="contacts">
        <div
            class="contacts__item"
            v-for="(item, index) in data" :key="index"
        >
            <z-caption
                tag="h2"
                size="2xl"
                class="contacts__item-title"
                v-html="item.title"
                v-if="item.title"
            ></z-caption>
            <div class="row">
                <div class="col-default-11 col-default-offset-1 col-tablet-12 col-tablet-offset-0">
                    <z-caption
                        tag="h3"
                        v-html="item.subtitle"
                        v-if="item.subtitle"
                    ></z-caption>
                    <div class="row">
                        <div
                            class="col-default-6 col-tablet-12"
                            v-if="item.address"
                        >
                            <div class="contacts__address">
                                <span class="contacts__address-title">Адрес:</span>
                                <span v-html="item.address"></span>
                                <div class="contacts__address-link">
                                    <z-link
                                        :href="item['address-link']"
                                        external
                                        target="_blank"
                                        v-if="item['address-link']"
                                    >Смотреть на&nbsp;карте</z-link>
                                </div>
                            </div>
                            <div class="u-top-margin--xs u-bold">
                                <z-link :href="item.feedback.link" v-html="item.feedback.text"></z-link>
                            </div>
                        </div>
                        <div
                            class="col-default-6 col-tablet-12"
                            v-if="item.fax && item.fax.length ||
                            item.phone && item.phone.length ||
                            item.email && item.email.length"
                        >
                            <div
                                class="contacts__item-info"
                                v-if="item.phone && item.phone.length"
                            >
                                <span class="contacts__info-title">Телефон:</span>
                                <div
                                    v-for="(phone, indexPhone) in item.phone"
                                    :key="`${indexPhone} + p`"
                                    class="contacts__phone-item"
                                >
                                    <z-link :href="`tel:${phone.url}`">
                                        <span v-html="phone.text"></span>
                                    </z-link>
                                    <span class="contacts__item-added-text" v-html="phone['add-text']" v-if="phone['add-text']"></span>
                                </div>
                            </div>
                            <div
                                class="contacts__item-info"
                                v-if="item.fax && item.fax.length"
                            >
                                <span class="contacts__info-title">Факс:</span>
                                <z-link
                                    v-for="(fax, indexFax) in item.fax"
                                    :key="`${indexFax} + f`"
                                    :href="`fax:${fax.url}`"
                                    class="contacts__fax-item"
                                >
                                    <span v-html="fax.text"></span>
                                </z-link>
                            </div>
                            <div v-if="item.email" class="contacts__item-info">
                                <span class="contacts__info-title">E-mail:</span>
                                <z-link
                                    :href="`mailto:${item.email}`"
                                >
                                    <span v-html="item.email"></span>
                                </z-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'contacts',
    props: {
        data: Array
    }
}
</script>

<style lang="scss">
.contacts__item {
    @include padding-level (bottom, XL);
    @include padding-level (top, XL);
    border-bottom: 1px solid $token-colors-black-15;

    &:last-of-type {
        border-bottom: none;
    }

    &-info {
        display: flex;

        & + & {
            @include margin-level (top, XS);
        }

        @include breakpoint(mobile) {
            flex-wrap: wrap;
        }
    }

    &-added-text {
        display: inline-block;
        margin-left: 4px;
    }
}

.contacts {
    &__info-title {
        color: $token-colors-black-50;
        margin-right: $token-spacers-2-xs;
    }

    &__info-wrapper {
        display: flex;
        flex-direction: column;
    }

    &__address-link {
        display: block;
        @include margin-level (top, XS);
    }

    &__phone-item,
    &__fax-item {
        flex-shrink: 0;
        display: inline-flex;

        &:after {
            content: ',\00a0';
            display: inline-block;
            position: relative;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }
}
</style>
