<template>
    <div class="image-block">
        <div class="image-block__content">
            <slot />
        </div>
        <div class="image-block__image" :style="`background-image: url('${image}');`"></div>
    </div>
</template>

<script>
export default {
    name: 'image-block',
    props: {
        image: String
    }
}
</script>

<style lang="scss">
.image-block {
    display: flex;
    @include padding-level(top, M);
    @include padding-level(right, M);
    @include padding-level(bottom, M);
    @include padding-level(left, M);
    border-radius: 4px;
    border: 1px solid $token-colors-black-15;

    @include breakpoint (tablet) {
        flex-direction: column;
    }

    &__content {
        flex-basis: 50%;
        max-width: 50%;

        @include breakpoint (tablet) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    &__image {
        flex-basis: 50%;
        max-width: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 30%;
        margin-left: $token-spacers-xl;
        @include margin-level(right, M, true);

        @include breakpoint (tablet) {
            margin-top: $token-spacers-s;
            @include margin-level(left, M, true);
            @include margin-level(bottom, M, true);
            flex-basis: auto;
            max-width: none;
        }

        @include breakpoint (mobile) {
            padding-bottom: 107%;
            background-position: 25% 0%;
            background-size: 230%;
        }
    }
}
</style>
