/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/big/mp4': {
    width: 56,
    height: 56,
    viewBox: '0 0 56 56',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.933 4.667H30.6a1 1 0 01.707.293l1.807 1.807h-.011l.472.472L45.1 18.765v-.01l1.807 1.806a1 1 0 01.293.707V46.9a5.6 5.6 0 01-5.6 5.6H14.933a5.6 5.6 0 01-5.6-5.6V10.267a5.6 5.6 0 015.6-5.6zM45.101 20.94H32.875a1.4 1.4 0 01-1.4-1.4V6.767H14.933a3.5 3.5 0 00-3.5 3.5V46.9a3.5 3.5 0 003.5 3.5h26.668a3.5 3.5 0 003.5-3.5V20.941zm-11.526-2.1v-8.633l8.633 8.633h-8.633z" _fill="#1092DC"/><path pid="1" d="M16.467 37.833v-8.46h1.284l3 5.532 2.988-5.532H25v8.46h-1.392v-5.7l-2.424 4.416h-.9l-2.424-4.392v5.676h-1.392zm10.154 0v-8.46h3.672c.928 0 1.644.232 2.148.696.504.464.756 1.112.756 1.944 0 .832-.252 1.484-.756 1.956-.504.464-1.22.696-2.148.696h-2.136v3.168h-1.536zm1.536-4.38h1.896c1.104 0 1.656-.48 1.656-1.44 0-.952-.552-1.428-1.656-1.428h-1.896v2.868zm9.316 4.38v-1.608H33.79v-1.128l3.948-5.724h1.272v5.58h1.188v1.272H39.01v1.608h-1.536zm0-2.88v-3.228l-2.208 3.228h2.208z" _fill="#1092DC"/>'
  }
})
