<template>
    <footer class="footer">
        <div class="container">
            <div class="footer__wrapper">
                <div class="row">
                    <div class="col-default-3 col-v-tablet-12">
                        <div class="footer__logo hide-me--tablet">
                            <z-logo site-dir="/" />
                        </div>
                        <z-copyright
                            year-current
                            text-after="Заполярный транспортный филиал ПАО&nbsp;«ГМК&nbsp;«Норильский никель»"
                        ></z-copyright>
                    </div>
                    <div class="col-default-8 col-default-offset-1 hide-me--tablet">
                        <navigation-bottom :data="footerNavData" columns="2" :additional-data="footerNavAddedData" />
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import NavigationBottom from '@/components/NavigationBottom'

export default {
    name: 'footer-layout',
    components: {
        NavigationBottom
    },
    data () {
        return {
            footerNavData: this.$root.app.components.navigation.top,
            footerNavAddedData: this.$root.app.components.navigation.bottom
        }
    }
}
</script>

<style lang="scss">
.footer {
    background: $token-colors-black-5;

    &__wrapper {
        padding: 48px 0;
    }

    &__logo {
        max-width: 200px;
        margin-bottom: $token-spacers-s;

        @include breakpoint (tablet) {
            display: none;
        }
    }
}
</style>
