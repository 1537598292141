<template>
    <form @submit.prevent="submitForm" v-if="!successFormSend">
        <z-caption tag="h2" size="2xl">Заявка на&nbsp;оказание услуги по&nbsp;погрузке/выгрузке контейнеров с/на автотранспорт</z-caption>
        <span class="u-block u-bottom-margin--s black-50-text text-size-xs">(* - обязательное поле)</span>
        <z-date-picker
            required
            placeholder="Планируемая дата приезда"
            v-model="formData.date"
            ref="date"
            :disabled-date="getDisabledDate"
            clearable
        ></z-date-picker>
        <z-caption tag="h3" class="u-top-margin--s">Данные о&nbsp;водителе и&nbsp;машине</z-caption>
        <z-input
            v-model="formData['driver-fio']"
            name="driver-fio"
            ref="driver-fio"
            required
            placeholder="ФИО водителя (укажите полностью)"
            class="u-bottom-margin--s"
        ></z-input>
        <z-input
            v-model="formData['car-number']"
            ref="car-number"
            required
            name="car-number"
            placeholder="Государственный номер автомобиля"
            class="u-bottom-margin--s"
        ></z-input>
        <z-input
            v-model="formData['car-mark']"
            ref="car-mark"
            required
            name="car-mark"
            placeholder="Марка автомобиля"
            class="u-bottom-margin--s"
        ></z-input>
        <z-select
            class="u-bottom-margin--s"
            :data="[
                {
                    'id': 1,
                    'selected': false,
                    'disabled': false,
                    'text': 'Ввоз груза'
                },
                {
                    'id': 2,
                    'selected': false,
                    'disabled': false,
                    'text': 'Вывоз груза'
                }
            ]"
            v-model="formData['visit-purpose']"
            ref="visit-purpose"
            required
            placeholder="Цель приезда"
        ></z-select>
        <z-input
            v-model="formData.organisation"
            ref="organisation"
            required
            name="organisation"
            placeholder="Организация"
            class="u-bottom-margin--s"
        ></z-input>
        <z-select
            class="u-bottom-margin--s"
            :data="[
                {
                    'id': 'ppk-1',
                    'selected': false,
                    'disabled': false,
                    'text': 'ППК №1'
                },
                {
                    'id': 'ppk-2',
                    'selected': false,
                    'disabled': false,
                    'text': 'ППК №2'
                }
            ]"
            v-model="formData['cargo-area']"
            ref="cargo-area"
            placeholder="Грузовой район"
        ></z-select>

        <z-caption tag="h3" class="u-top-margin--s">Данные о&nbsp;судне и&nbsp;грузе</z-caption>
        <z-caption tag="h4" size="l">Укажите номера контейнеров, обслуживаемых по&nbsp;данной заявке:</z-caption>
        <div class="row">
            <div class="col-default-6 col-mobile-12">
                <z-input
                    v-model="formData.container1"
                    type="text"
                    ref="container1"
                    required
                    name="container1"
                    placeholder="Контейнер №1"
                ></z-input>
            </div>
            <div class="col-default-6 col-mobile-12">
                <z-input
                    v-model="formData.container2"
                    type="text"
                    ref="container2"
                    name="container2"
                    placeholder="Контейнер №2"
                ></z-input>
            </div>
        </div>

        <z-input
            v-if="formData['visit-purpose'] === 2"
            v-model="formData['boat-name']"
            ref="boat-name"
            name="boat-name"
            placeholder="Название судна"
            class="u-bottom-margin--s"
        ></z-input>

        <z-caption tag="h2" class="u-top-margin--s">Контактные данные представителя</z-caption>
        <z-input
            v-model="formData['representative-fio']"
            type="text"
            ref="representative-fio"
            required
            name="representative-fio"
            placeholder="ФИО представителя (укажите полностью)"
            class="u-bottom-margin--s"
        ></z-input>
        <z-input
            v-model="formData['representative-phone']"
            type="tel"
            ref="representative-phone"
            required
            name="representative-phone"
            placeholder="Телефон представителя"
            class="u-bottom-margin--s"
        ></z-input>
        <z-input
            v-model="formData['representative-email']"
            type="email"
            ref="representative-email"
            name="representative-email"
            placeholder="E-mail представителя"
            class="u-bottom-margin--s"
        ></z-input>

        <div class="form-captcha">
            <div class="form-captcha__wrapper">
                <div class="form-captcha__captcha">
                    <div class="form-captcha__img-wrapper">
                        <img
                            class="form-captcha__img"
                            v-if="isUpdatedCapcha"
                            :src="'/api/captcha/?' + capchaSalt"
                            width="100"
                            height="50"
                            alt="Captcha"
                        />
                    </div>
                    <z-button
                        class="form-captcha__reset-btn"
                        type="button"
                        rounded
                        only-icon
                        size="s"
                        kind="bordered"
                        @click="updateCapcha"
                    >
                        <z-icon name="tools/refresh" width="28" height="28"></z-icon>
                    </z-button>
                </div>
                <z-input
                    v-model="captcha"
                    type="text"
                    name="captcha"
                    ref="captcha"
                    required
                    placeholder="Введите код с картинки"
                    class="form-captcha__input"
                ></z-input>
            </div>
        </div>
        <z-button>
            Отправить
        </z-button>
        <p class="u-top-margin--m">Нажимая кнопку «Отправить», я соглашаюсь на&nbsp;обработку данных и&nbsp;принимаю условия <z-link href="/cookies-policy/">Политики конфиденциальности</z-link> приложений сайта</p>
    </form>
    <z-notification v-else>
        <template v-slot:image>
            <z-image src="/images/znotification/form-success.svg" width="316" height="266"></z-image>
        </template>
        <template v-slot:description>
            <z-caption size="m">Ваше сообщение принято, спасибо!</z-caption>
            <z-link href="/clients/ztf-clients/applications/">Направить еще одну заявку</z-link>
        </template>
    </z-notification>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { sendApplicationsForm } from '@/api/applications-form.js'
import { sendCaptcha } from '@/api/captcha.js'

export default {
    name: 'contacts-form',
    data () {
        return {
            isLoading: false,
            formData: {
                date: '',
                'driver-fio': '',
                'car-number': '',
                'car-mark': '',
                'visit-purpose': '',
                organisation: '',
                'cargo-area': '',
                container1: '',
                container2: '',
                'boat-name': '',
                'representative-fio': '',
                'representative-phone': '',
                'representative-email': '',
                phrase: this.captcha,
                ajax_call: 'Y'
            },
            capchaSalt: Date.now(),
            captcha: '',
            captchaImg: null,
            isUpdatedCapcha: true,
            successFormSend: false
        }
    },
    methods: {
        getDisabledDate (date) {
            return date <= new Date()
        },
        validateRequiredInputs () {
            const requiredFields = ['date', 'driver-fio', 'car-number', 'car-mark', 'visit-purpose', 'organisation', 'container1', 'representative-fio', 'representative-phone']
            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },
        updateCapcha () {
            this.isUpdatedCapcha = false
            this.captcha = ''
            this.capchaSalt = Date.now()
            setTimeout(() => {
                this.isUpdatedCapcha = true
            }, 500)
        },
        async submitForm () {
            if (this.isLoading) return

            const isValidRequiredInputs = this.validateRequiredInputs()

            if (isValidRequiredInputs) {
                try {
                    this.isLoading = true

                    if (this.captcha === '') {
                        showNotyfications('Введите код с картинки*', { type: 'error' })
                        return
                    }

                    const data = new FormData()

                    for (const key in this.formData) {
                        data.append(key, this.formData[key])
                    }
                    const captcha = {
                        phrase: this.captcha
                    }

                    const response = await sendCaptcha(captcha)

                    if (response.status === false) {
                        showNotyfications(response.message, { type: 'error' })
                    } else {
                        const formResponse = await sendApplicationsForm(data)

                        if (formResponse.status === 'success') {
                            showNotyfications('Отправка успешна', { type: 'success' })
                            this.successFormSend = true
                        } else {
                            showNotyfications('Ошибка отправки', { type: 'error' })
                        }
                    }
                    this.updateCapcha()
                } catch (error) {
                    console.log(error)
                    showNotyfications('Ошибка отправки', { type: 'error' })
                } finally {
                    this.isLoading = false
                }
            } else {
                showNotyfications('Заполните все обязательные поля', { type: 'error' })
            }
        }
    }
}
</script>
