<template>
    <nav
        class="navigation-bottom"
        aria-label="Bottom navigation"
    >
        <ul class="navigation-bottom__list" :class="{[`navigation-bottom__list--columns-${columns}`]: columns}">
            <li
                v-for="(itemA, indexData) in data"
                :key="indexData"
                class="navigation-bottom__item"
            >
                <z-link
                    class="u-semi-bold"
                    theme="dark"
                    :underlined="false"
                    :href="getHref(itemA)"
                    :title="itemA.name"
                >
                    <span v-html="itemA.name"></span>
                </z-link>
            </li>
            <li class="navigation-bottom__item">
                <z-link
                    class="u-semi-bold"
                    theme="dark"
                    :underlined="false"
                    href="/contacts/"
                    title="Контакты"
                >
                    <span>Контакты</span>
                </z-link>
            </li>
        </ul>
        <ul class="navigation-bottom__list" v-if="additionalData">
            <li
                v-for="(itemB, indexAddedData) in additionalData"
                :key="indexAddedData"
                class="navigation-bottom__item navigation-bottom__item--additional"
            >
                <z-link
                    theme="dark"
                    :underlined="false"
                    :href="getHref(itemB)"
                    :title="itemB.name"
                >
                    <span v-html="itemB.name"></span>
                </z-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'navigation-bottom',
    props: {
        data: Array,
        additionalData: Array,
        columns: [String, Number]
    },
    methods: {
        getHref (item) {
            if (Array.isArray(item.pageParams)) {
                return item.link
            }

            if (item.pageParams?.redirect) {
                return item.pageParams?.redirect
            } else {
                return item.link
            }
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
