<template>
    <div class="header-menu-wrapper" v-click-outside="hideChilds">
        <nav
            :class="[
                'header-menu-nav-links',
                { 'is-opened': navIndexOpened !== -1 },
            ]"
        >
            <ul class="header-menu-nav-links__list">
                <li
                    :class="[
                        'header-menu-nav-links__list-item',
                        {
                            'header-menu-nav-links__list-item--opened': indexA === navIndexOpened,
                            'is-active': checkActiveState(itemA)
                        }
                    ]"
                    v-for="(itemA, indexA) in data"
                    :key="indexA"
                >
                    <a
                        :href="getHref(itemA)"
                        :class="[
                            'header-menu-nav-links__item-link',
                            {
                                'header-menu-nav-links__item-link--is-parent': itemA.items && itemA.items.length,
                            }
                        ]"
                        @click="showChilds($event, indexA, itemA)"
                    >
                        <span
                            class="header-menu-nav-links__link-name"
                            v-html="itemA.name"
                        ></span>
                        <span class="header-menu-nav-links__link-arrow">
                            <z-icon
                                name="arrows/caret-small"
                                width="16"
                                height="16"
                                dir="down"
                            />
                        </span>
                    </a>
                </li>
            </ul>
        </nav>

        <div class="header-menu">
            <ul class="header-menu__list">
                <li
                    :class="[
                        'header-menu__list-item',
                        {
                            'header-menu__list-item--opened': navIndexOpened === indexA
                        }
                    ]"
                    v-for="(itemA, indexA) in data"
                    :key="indexA"
                >
                    <div class="container" v-if="navIndexOpened === indexA">
                        <div class="header-menu__submenu-wrapper" v-if="itemA.items && itemA.items.length">
                            <button type="button" class="header-menu__button" @click="hideChilds">
                                <z-icon
                                    name="tools/close"
                                    width="24"
                                    height="24"
                                    class="header-menu__button-icon"
                                ></z-icon>
                            </button>
                            <div class="header-menu__row">
                                <transition
                                    @enter="animationEnter"
                                    @leave="animationLeave"
                                >
                                    <div class="header-menu__col" v-if="firstObj">
                                        <z-caption
                                            :tag="isRedirect(firstObj) ? 'p' : 'a'"
                                            :href="isRedirect(firstObj) ? false : getHref(firstObj)"
                                            size="s"
                                            class="u-bottom-margin--xs-important black-40-text u-bold"
                                            v-if="!isRedirect(firstObj)"
                                        >
                                            <span v-html="firstObj.name"></span>
                                        </z-caption>
                                        <ul class="header-menu-item">
                                            <template
                                                v-for="(itemB, indexB) in itemA.items"
                                            >
                                                <li
                                                    class="header-menu-item__list-item"
                                                    :class="{
                                                        'is-active': itemB.active,
                                                        'is-opened': secondObj !== null && secondObj.link === itemB.link && secondObj.name === itemB.name
                                                    }"
                                                    :key="indexB"
                                                >
                                                    <a
                                                        :href="getHref(itemB)"
                                                        @click="showSecondLvl($event, itemB, '2')"
                                                        class="header-menu-item__item-link"
                                                        :target="itemB.params && itemB.params.BLANK ? '_blank' : false"
                                                    >
                                                        <span class="header-menu-item__item-link-name" v-html="itemB.name"></span>
                                                        <span v-if="itemB.items && itemB.items.length" class="header-menu-item__item-link-arrow">
                                                            <z-icon
                                                                name="arrows/caret"
                                                                width="14"
                                                                height="14"
                                                                dir="right"
                                                            />
                                                        </span>
                                                    </a>
                                                </li>
                                            </template>
                                        </ul>
                                    </div>
                                </transition>
                                <transition
                                    @enter="animationEnter"
                                    @leave="animationLeave"
                                >
                                    <div
                                        class="header-menu__col"
                                        v-if="secondObj"
                                    >
                                        <z-caption
                                            :tag="isRedirect(secondObj) ? 'p' : 'a'"
                                            :href="isRedirect(secondObj) ? false : getHref(secondObj)"
                                            size="s"
                                            class="u-bottom-margin--xs-important black-40-text u-bold"
                                            v-if="!isRedirect(secondObj)"
                                        >
                                            <span v-html="secondObj.name"></span>
                                        </z-caption>
                                        <ul
                                            class="header-submenu"
                                            v-if="
                                                secondObj &&
                                                secondObj.items &&
                                                secondObj.items.length
                                            "
                                        >
                                            <template
                                                v-for="(itemC, indexC) in secondObj.items"
                                            >
                                                <li
                                                    class="header-submenu__item"
                                                    :class="{
                                                        'is-active': itemC.active,
                                                        'is-opened': thirdObj !== null && thirdObj.link === itemC.link && thirdObj.name === itemC.name
                                                    }"
                                                    :key="indexC"
                                                >
                                                    <a
                                                        :href="getHref(itemC)"
                                                        @click="showThirdLvl($event, itemC, '3')"
                                                        class="header-submenu__item-link"
                                                        :target="itemC.params && itemC.params.BLANK ? '_blank' : false"
                                                    >
                                                        <span class="header-menu-item__item-link-name" v-html="itemC.name"></span>
                                                        <span v-if="itemC.items && itemC.items.length" class="header-menu-item__item-link-arrow">
                                                            <z-icon
                                                                name="arrows/caret"
                                                                width="14"
                                                                height="14"
                                                                dir="right"
                                                            />
                                                        </span>
                                                    </a>
                                                </li>
                                            </template>
                                        </ul>
                                    </div>
                                </transition>
                                <transition
                                    @enter="animationEnter"
                                    @leave="animationLeave"
                                >
                                    <div class="header-menu__col" v-if="secondObj && thirdObj && thirdObj.items && thirdObj.items.length">
                                        <z-caption
                                            :tag="isRedirect(thirdObj) ? 'p' : 'a'"
                                            :href="isRedirect(thirdObj) ? false : getHref(thirdObj)"
                                            size="s"
                                            class="u-bottom-margin--xs-important black-40-text u-bold"
                                            v-if="!isRedirect(thirdObj)"
                                        >
                                            <span v-html="thirdObj.name"></span>
                                        </z-caption>
                                        <ul class="header-submenu">
                                            <li
                                                class="header-submenu__item"
                                                :class="{
                                                    'is-active': itemD.active,
                                                }"
                                                v-for="(itemD, indexD) in thirdObj.items"
                                                :key="indexD"
                                            >
                                                <a
                                                    :href="getHref(itemD)"
                                                    class="header-submenu__item-link"
                                                    :target="itemD.params && itemD.params.BLANK ? '_blank' : false"
                                                >
                                                    <span
                                                        class="header-submenu__link-text"
                                                        v-html="itemD.name"
                                                    ></span>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </transition>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Velocity from 'velocity-animate'
import 'velocity-animate/velocity.ui.js'

export default {
    name: 'navigation-main',
    data () {
        return {
            data: this.$root.app.components.navigation.top,
            hovered: null,
            subMenu: false,
            subMenuItems: [],
            navIndexOpened: -1,
            firstObj: null,
            secondObj: null,
            thirdObj: null
        }
    },
    methods: {
        animationEnter (el, done) {
            Velocity(el, 'transition.slideLeftBigIn', { duration: 300 })
        },
        animationLeave (el, done) {
            Velocity(el, 'transition.slideRightBigOut', { duration: 300 })
        },
        showSecondLvl (event, item) {
            this.secondObj = null
            this.thirdObj = null
            if (item.items?.length) {
                event.preventDefault()
                this.secondObj = item
            }
        },
        showThirdLvl (event, item) {
            this.thirdObj = null
            if (item.items?.length) {
                event.preventDefault()
                this.thirdObj = item
            }
        },
        showChilds (event, i, item) {
            this.firstObj = item
            this.secondObj = null
            this.thirdObj = null
            if (item.hasOwnProperty('items') && item.items.length) {
                event.preventDefault()
                this.navIndexOpened === i
                    ? this.hideChilds()
                    : (this.navIndexOpened = i)
            } else {
                this.hideChilds()
            }
        },
        hideChilds () {
            this.firstObj = null
            this.secondObj = null
            this.thirdObj = null
            this.navIndexOpened = -1
        },
        getHref (item) {
            if (Array.isArray(item.pageParams)) {
                return item.link
            }

            if (item.pageParams?.redirect) {
                return item.pageParams?.redirect
            } else {
                return item.link
            }
        },
        isRedirect (item) {
            if (Array.isArray(item.pageParams)) {
                return false
            }

            if (item.pageParams?.redirect) {
                return true
            } else {
                return false
            }
        },
        checkActiveState (item) {
            if (item.active === true) return true

            for (let i = 0; i < item.items?.length; i++) {
                if (item.items[i].active === true) return true
            }

            return false
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
