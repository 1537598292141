<template>
    <div class="main-page">
        <div class="container">
            <z-card class="main-page__banner" size="l" image="/images/main-page/mainpage.jpg" min-height-auto>
                <template v-slot:top>
                    <div class="u-space-between">
                        <z-caption tag="h1" size="3xl" weight="700" class="white-text">Заполярный транспортный филиал ПАО&nbsp;«ГМК «Норильский никель»</z-caption>
                        <weather-widget lat="69.40583" lon="86.17778" location="Дудинка" class="main-page__weather-widget"></weather-widget>
                    </div>
                </template>
                <template v-slot:content>
                    <a href="/ship-handling/sea/" class="attention-block">
                        <div class="attention-block__text">Изменения в&nbsp;порядке обработки Морских судов</div>
                    </a>
                </template>
            </z-card>
            <section class="u-top-margin--xl">
                <div class="row">
                    <div class="col-default-6 col-tablet-12">
                        <z-card class="u-entire-height" theme="gray">
                            <template v-slot:title>
                                <z-caption tag="h3" weight="700">Реестр обработки</z-caption>
                            </template>
                            <template v-slot:content>
                                <p class="u-top-margin--s u-bottom-margin--s black-text">Порт обладает большим парком портальных кранов, в&nbsp;том числе уникальными мобильными кранами Liebherr арктической модификации</p>
                                <div class="u-bottom-margin--xs">
                                    <z-link href="/ship-handling/river/" icon="tools/link" theme="dark" class="u-semi-bold">Речные суда</z-link>
                                </div>
                                <div class="u-bottom-margin--xs">
                                    <z-link href="/ship-handling/sea/" icon="tools/link" theme="dark" class="u-semi-bold">Морские суда</z-link>
                                </div>
                                <div>
                                    <z-link href="/ship-handling/bulk-cargo/" icon="tools/link" theme="dark" class="u-semi-bold">Суда с&nbsp;сыпучими грузами</z-link>
                                </div>
                            </template>
                        </z-card>
                    </div>
                    <div class="col-default-6 col-tablet-12">
                        <z-card class="u-entire-height" size="l" image="/images/main-page/back-image.jpg">
                            <template v-slot:title>
                                <z-caption tag="h3" weight="700">Расстановка судов</z-caption>
                            </template>
                            <template v-slot:content>
                                <div class="u-top-margin--s">
                                    <z-link href="/ship-arrangement/" icon="tools/link" theme="dark" class="u-semi-bold">Схема расстановки судов и&nbsp;кранов<br>по&nbsp;причалам ЗТФ</z-link>
                                </div>
                            </template>
                        </z-card>
                    </div>
                </div>
            </section>

            <section class="u-top-margin--xl" alt="Компания.">
                <image-block image="/images/main-page/map.svg">
                    <z-caption tag="h2" size="2xl" weight="700">Компания</z-caption>
                    <p>Заполярный транспортный филиал ПАО ГМК Норильский никель осуществляет перевалку и&nbsp;транспортировку большей части грузов на&nbsp;полуостров Таймыр. За&nbsp;четыре месяца (июнь&nbsp;&mdash; октябрь) с&nbsp;морского направления и&nbsp;по&nbsp;реке Енисей на&nbsp;полуостров Таймыр завозится весь необходимый для населения региона и&nbsp;промышленных предприятий ассортимент: горюче-смазочные материалы, продовольствие, промышленное и&nbsp;техническое оборудование, а&nbsp;также товары бытового назначения.</p>
                    <div class="row">
                        <div class="col-default-4 col-mobile-12">
                            <z-key-indicator
                                value="4"
                                text-after="млн тонн"
                                detail="груза"
                                size="l"
                            ></z-key-indicator>
                        </div>
                        <div class="col-default-4 col-mobile-12">
                            <z-key-indicator
                                value="30"
                                text-after="причалов"
                                detail="общего пользования ЗТФ"
                                size="l"
                            ></z-key-indicator>
                        </div>
                        <div class="col-default-4 col-mobile-12">
                            <z-key-indicator
                                value="85"
                                text-after="лет"
                                detail="работы ЗТФ"
                                size="l"
                            ></z-key-indicator>
                        </div>
                    </div>
                    <z-link href="/company/about/" icon="arrows/round-arrow" dir="right" icon-position="right" class="u-top-margin--s">Подробнее о&nbsp;компании</z-link>
                </image-block>
            </section>
            <section class="u-top-margin--xl">
                <z-card theme="gray" min-height-auto>
                    <template v-slot:title>
                        <z-caption tag="h3" weight="700">Основные контакты и&nbsp;реквизиты</z-caption>
                    </template>
                    <template v-slot:content>
                        <div class="u-top-margin--s">
                           Email:<z-link href="mailto:ztf@nornik.ru" class="u-semi-bold u-left-margin--xs">ztf@nornik.ru</z-link>
                        </div>
                        <div class="u-top-margin--xs text-size-s">
                            Адрес:<span class="u-semi-bold u-left-margin--xs">647000, Россия, Красноярский край, г.&nbsp;Дудинка, ул.&nbsp;Советская, 43</span>
                        </div>
                        <z-link href="/contacts/" icon="arrows/round-arrow" dir="right" icon-position="right" class="u-top-margin--s">Все контакты</z-link>
                    </template>
                </z-card>
            </section>
        </div>
    </div>
</template>
<script>

export default {
    name: 'main-page'
}
</script>

<style lang="scss" src="./index.scss"></style>
