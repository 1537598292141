/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/webm': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.667 3.333h11.072a1 1 0 01.707.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.667a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.548 11.625h-8.733a1 1 0 01-1-1V4.833H10.667a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.048a2.5 2.5 0 002.5-2.5V14.958zm-8.233-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" d="M10.707 25.765l1.024-4.997h.633l-.026 1.253-1.108 5.146h-.663l.14-1.402zm-.65-4.997l.813 4.962.035 1.437h-.698l-1.209-6.399h1.06zm3.42 4.944l.803-4.944h1.055l-1.204 6.399h-.699l.044-1.455zm-.858-4.944l1.015 5.01.132 1.389h-.664l-1.08-5.15-.027-1.249h.624zm6.904 5.507v.892h-2.777v-.892h2.777zm-2.496-5.507v6.399h-1.068v-6.399h1.068zm2.123 2.663v.88h-2.404v-.88h2.404zm.347-2.663v.897h-2.751v-.897h2.75zm2.786 3.516h-1.305l-.005-.817h1.156a.925.925 0 00.457-.106.677.677 0 00.286-.308c.067-.134.101-.297.101-.487 0-.211-.03-.383-.088-.514a.567.567 0 00-.277-.295 1.061 1.061 0 00-.474-.092h-.84v5.502h-1.068v-6.399h1.908c.299 0 .565.035.8.106.237.067.437.171.601.312.165.14.29.32.374.54.088.217.132.475.132.774 0 .26-.056.5-.167.72a1.38 1.38 0 01-.497.537c-.216.137-.486.22-.808.246l-.286.28zm-.04 2.883h-1.617l.492-.892h1.126c.19 0 .347-.041.47-.123a.749.749 0 00.277-.343 1.32 1.32 0 00.092-.514c0-.205-.026-.384-.08-.537a.69.69 0 00-.254-.351.797.797 0 00-.466-.123h-1.077l.01-.817h1.375l.246.312c.307.005.558.082.751.228.194.144.336.33.427.558.09.229.136.473.136.734 0 .407-.076.75-.229 1.028-.152.276-.37.485-.655.629-.28.14-.622.21-1.023.21zm3.16-6.399h.972l1.472 4.76 1.467-4.76h.901l-1.968 6.399h-.805l-2.039-6.399zm-.364 0h.896l.145 4.074v2.325H25.04v-6.399zm4.71 0h.901v6.399H29.61v-2.325l.14-4.074z" _fill="#1092DC"/>'
  }
})
