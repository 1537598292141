<template>
    <div class="z-copyright">
        <span
            v-if="textBefore"
            class="z-copyright__text"
        >
            <span v-html="textBefore"></span>
        </span>
        <span>&copy;</span>
        <span
            v-if="textAfter"
            class="z-copyright__text"
        >
            <span v-html="textAfter"></span>, <span class="u-inline">2016 — {{ currentYear }}</span>
        </span>
    </div>
</template>

<script>
export default {
    name: 'z-copyright',
    props: {
        textAfter: String,
        textBefore: String
    },
    computed: {
        currentYear () {
            return new Date().getFullYear()
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
