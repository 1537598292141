/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/mp4': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.667 3.333h11.072a1 1 0 01.707.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.667a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.548 11.625h-8.733a1 1 0 01-1-1V4.833H10.667a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.048a2.5 2.5 0 002.5-2.5V14.958zm-8.233-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" d="M10.39 27.167v-7.05h1.07l2.5 4.61 2.49-4.61h1.05v7.05h-1.16v-4.75l-2.02 3.68h-.75l-2.02-3.66v4.73h-1.16zm8.461 0v-7.05h3.06c.773 0 1.37.193 1.79.58.42.386.63.926.63 1.62 0 .693-.21 1.236-.63 1.63-.42.386-1.017.58-1.79.58h-1.78v2.64h-1.28zm1.28-3.65h1.58c.92 0 1.38-.4 1.38-1.2 0-.794-.46-1.19-1.38-1.19h-1.58v2.39zm7.764 3.65v-1.34h-3.07v-.94l3.29-4.77h1.06v4.65h.99v1.06h-.99v1.34h-1.28zm0-2.4v-2.69l-1.84 2.69h1.84z" _fill="#1092DC"/>'
  }
})
