// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import { TippyComponent } from 'vue-tippy'
import App from './App'
import store from './store/'
import { localize } from '@/utils/i18n'

import '@/utils/filter' // global
import '@/utils/modernizr-custom' // global
import '@/utils/polyfills/object-fit' // global
import '@/utils/correctExtensions' // global
import { VueAxios } from '@/utils/request'
import { AppDeviceEnquire } from '@/utils/mixin'
import { showNotyfications } from '@/utils/notifications'

/* Components */
import NavigationTop from './components/NavigationTop'
import NavigationMain from './components/NavigationMain'
import NavigationMobile from './components/NavigationMobile'
import NavigationBottom from './components/NavigationBottom'
import SiteSelector from './components/SiteSelector'
import NavigationLeft from './components/NavigationLeft'
import Remind from './components/Remind'
import CookiePopup from './components/CookiePopup'
import NavigationRight from './components/NavigationRight'
import { MosaicBlock, MosaicBlockItem } from './components/MosaicBlock'
import IconBlock from './components/IconBlock'
import { TimeLine, TimeLineItem } from './components/TimeLine'
import { Slider, Slide, SlideContent } from './components/Slider'
import WeatherWidget from './components/WeatherWidget'
import ImageBlock from './components/ImageBlock'

/* Views */
import SiteMap from './views/SiteMap'
import { Layout, HeaderLayout, FooterLayout } from './views/Layout'
import Page404 from './views/Page404'
import Files from './views/Files'
import MainPage from './views/MainPage'
import ApplicationsForm from './views/ApplicationsForm'
import { Contacts, FeedbackForm } from './views/Contacts'

/* Components */
Vue.component('tippy-tooltip', TippyComponent)
Vue.component('navigation-top', NavigationTop)
Vue.component('navigation-main', NavigationMain)
Vue.component('navigation-mobile', NavigationMobile)
Vue.component('navigation-bottom', NavigationBottom)
Vue.component('site-selector', SiteSelector)
Vue.component('icon-block', IconBlock)
Vue.component('mosaic-block', MosaicBlock)
Vue.component('mosaic-block-item', MosaicBlockItem)
Vue.component('time-line', TimeLine)
Vue.component('time-line-item', TimeLineItem)
Vue.component('navigation-left', NavigationLeft)
Vue.component('remind', Remind)
Vue.component('navigation-right', NavigationRight)
Vue.component('slider', Slider)
Vue.component('slide', Slide)
Vue.component('slide-content', SlideContent)
Vue.component('cookie-popup', CookiePopup)
Vue.component('weather-widget', WeatherWidget)
Vue.component('image-block', ImageBlock)

/* Views */
Vue.component('site-map', SiteMap)
Vue.component('layout', Layout)
Vue.component('header-layout', HeaderLayout)
Vue.component('footer-layout', FooterLayout)
Vue.component('site-map', SiteMap)
Vue.component('page-404', Page404)
Vue.component('files', Files)
Vue.component('main-page', MainPage)
Vue.component('applications-form', ApplicationsForm)
Vue.component('contacts', Contacts)
Vue.component('feedback-form', FeedbackForm)

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
    './components',
    true,
    /Z[\w-]+\/index\.js$/
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    let componentName = fileName.split('/')[1]

    for (let key in componentConfig) {
        if (componentConfig.hasOwnProperty(key)) {
            if (key !== 'default') componentName = key
            Vue.component(componentName, componentConfig[key])
        }
    }
})

if (process.env.NODE_ENV !== 'production') {
    window.onerror = function (msg, url, lineNo, columnNo, error) {
        showNotyfications(`${msg}<br>${url}#${lineNo}`, {
            type: 'error'
        })
    }

    Vue.config.errorHandler = function (err, vm, info) {
        console.error(`Error: ${err.toString()}\nInfo: ${info}\nVm component: ${vm.$options._componentTag}`)
        showNotyfications(`Error: ${err.toString()}<br>Info: ${info}<br>Vm component: ${vm.$options._componentTag}`, {
            type: 'error'
        })
    }

    Vue.config.warnHandler = function (msg, vm, trace) {
        console.warn(`Warn: ${msg.toString()}\nInfo: ${trace}\nVm component: ${vm.$options._componentTag}`)
        showNotyfications(`Warn: ${msg.toString()}<br>Info: ${trace}<br>Vm component: ${vm.$options._componentTag}`, {
            type: 'alert'
        })
    }

    Vue.config.productionTip = false
}

Object.defineProperty(Vue.prototype, '$bus', {
    get () {
        return this.$root.bus
    }
})

Vue.config.productionTip = false

Vue.use(VueAxios)

/* eslint-disable no-new */
new Vue({
    el: '#app',
    mixins: [AppDeviceEnquire],
    store,
    data: {
        lang: window.App.page.lang,
        bus: new Vue({}),
        app: window.App
    },
    components: {
        App
    },
    mounted () {
        this.$el.classList.add('is-loaded')
    },
    methods: {
        localize: localize
    }
})
