/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/refresh-double': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.13 5.07a7.5 7.5 0 018.173 1.627l1.902 1.901h-2.69a.75.75 0 000 1.5h4.5a.748.748 0 00.75-.763V4.848a.75.75 0 00-1.5 0v2.69l-1.9-1.902a9 9 0 00-12.729 0 .75.75 0 001.06 1.06A7.5 7.5 0 019.13 5.072zM2.697 13.96a.749.749 0 01.287-.057h4.5a.75.75 0 010 1.5H4.795l1.902 1.901a7.502 7.502 0 0010.606 0 .75.75 0 111.06 1.06 9 9 0 01-12.727 0l-1.902-1.9v2.689a.75.75 0 01-1.5 0v-4.5a.748.748 0 01.463-.693z" _fill="#81899D"/>'
  }
})
