<template>
    <div class="z-input-file">
        <label
            :for="name"
            class="z-input-file__label"
            :class="{
                'is-disabled': disabled
            }"
        >
            <input
                :id="name"
                type="file"
                ref="file"
                :name="name"
                :disabled="disabled"
                :multiple="multiple"
                @change="onChange($event.target)"
            />
            <z-icon
                name="tools/paperclip"
                width="24"
                height="24"
            />
            <span v-html="labelText"></span>
        </label>
        <z-filelist class="z-input-file__list" v-if="files.length">
            <z-filelist-item
                size="l"
                :icon="`file/big/${file.type}`"
                :file-size="getFormatedSize(file.size)"
                :class="[
                    'z-input-file__item',
                    { 'is-error': !isFormatCorrect(file)}
                ]"
                v-for="(file, index) in files"
                :key="index"
            >
                <p class="z-input-file__item-name">
                    <span>{{ file.name }}</span>
                    <z-icon
                        name="tools/close-small"
                        width="24"
                        height="24"
                        @click="remove(file, index)"
                    />
                </p>
            </z-filelist-item>
        </z-filelist>
        <span
            :class="[
                'z-input-file__error',
                errorClass
            ]"
            v-html="error"
            v-if="error && !isValid"
        ></span>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'z-input-file',
    props: {
        name: {
            type: String,
            required: true
        },
        required: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiple: {
            type: Boolean,
            default: false
        },
        accept: String,
        placeholder: String,
        errorClass: {
            type: String,
            default: ''
        },
        totalWeight: [String, Number],
        totalFiles: [String, Number]
    },

    data () {
        return {
            files: [],
            uploaded: new DataTransfer(),
            isValid: null,
            error: '',
            text: {
                required: localize({
                    ru: 'Поле обязательно для заполнения',
                    en: 'This field is required'
                }),
                format: localize({
                    ru: 'Некорректный формат',
                    en: 'Invalid format'
                }),
                exceededAll: localize({
                    ru: 'Превышено количество прикрепляемых файлов и общий размер файлов',
                    en: 'Number of attachments and total size of files exceeded'
                }),
                exceededWeight: localize({
                    ru: 'Превышен общий размер файлов',
                    en: 'Total file size exceeded'
                }),
                exceededCount: localize({
                    ru: 'Превышено количество прикрепляемых файлов',
                    en: 'The number of attached files is exceeded'
                })
            },
            label: localize({
                ru: 'Прикрепить файлы',
                en: 'Attach files'
            })
        }
    },
    mounted () {
        this.$root.$bus.$on('clear', name => {
            if (this.name === name) this.clear()
        })
    },
    beforeDestroy () {
        this.$root.$bus.$off('clear', name => {
            if (this.name === name) this.clear()
        })
    },
    computed: {
        labelText () {
            if (this.placeholder) return this.placeholder
            return this.label
        },
        filesWeight () {
            if (!this.files.length) return 0

            let result = this.files.reduce(function (accumulator, currentValue) {
                return accumulator + currentValue.size
            }, 0)

            return result / Math.pow(1024, 2) // перевод байтов в мегабайты
        },
        filesCounter () {
            if (!this.files.length) return 0

            return this.files.length
        }
    },
    methods: {
        isFormatCorrect (file) {
            if (!this.accept) return true
            const formatsArr = this.accept.replaceAll('.', '').split(', ')

            return formatsArr.indexOf(file.type) !== -1
        },
        onChange (target) {
            this.updateList(target.files)

            target.files.forEach(item => {
                this.uploaded.items.add(item)
            })

            this.$nextTick(() => this.$emit('change', this.uploaded.files))
            this.validate()
        },
        updateList (data) {
            this.files.push(...Array.from(data).map(item => {
                const re = /(?:\.([^.]+))?$/
                return {
                    name: item.name,
                    size: item.size,
                    type: re.exec(item.name)[1]
                }
            }))
        },
        remove (file, index) {
            this.files.splice(index, 1)

            let dt = new DataTransfer()
            this.uploaded.files.forEach(item => {
                if (item.name !== file.name) {
                    dt.items.add(item)
                }
            })

            if (dt.files.length === 0) {
                this.clear()
                return
            }

            this.uploaded = dt
            this.$nextTick(() => this.$emit('change', this.uploaded.files))
            this.validate()
        },
        getFormatedSize (size) {
            let result = size / 1024
            if (result >= 1024) {
                return `${(result / 1024).toFixed(1)} ${localize({'ru': 'Мб', 'en': 'Mb'})}`
            }

            return `${result.toFixed(1)} ${localize({'ru': 'Кб', 'en': 'Kb'})}`
        },
        isFilesCorrect () {
            let result = true
            this.files.forEach(item => {
                if (!this.isFormatCorrect(item)) result = false
            })
            return result
        },
        validate () {
            if (this.required && this.files.length === 0) {
                this.isValid = false
                this.error = this.text.required
                return { value: false, error: this.error}
            }

            if (!this.isFilesCorrect()) {
                this.isValid = false
                this.error = this.text.format
                return { value: false, error: this.error}
            }

            if ((Number(this.filesCounter) > Number(this.totalFiles)) && (Number(this.filesWeight) > Number(this.totalWeight))) {
                this.isValid = false
                this.error = this.text.exceededAll
                return { value: false, error: this.error}
            }

            if (Number(this.filesWeight) > Number(this.totalWeight)) {
                this.isValid = false
                this.error = this.text.exceededWeight
                return { value: false, error: this.error}
            }

            if (Number(this.filesCounter) > Number(this.totalFiles)) {
                this.isValid = false
                this.error = this.text.exceededCount
                return { value: false, error: this.error}
            }

            this.isValid = true
            this.error = ''
            return { value: true, error: '' }
        },
        clear () {
            this.files = []
            this.uploaded = new DataTransfer()
            this.$refs.file.value = null
            this.$nextTick(() => this.$emit('change', { value: this.uploaded.files, name: this.name }))
            this.validate()
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
