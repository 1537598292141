<template>
    <li class="timeline-item">
        <slot />
    </li>
</template>

<script>
export default {
    name: 'time-line-item'
}
</script>

<style lang="scss">
    .timeline-item {
        @include padding-level (bottom, M);
        padding-left: 68px;
        position: relative;

        &:last-child {
            padding-bottom: 0;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 44px;
            height: 44px;
            background-color: #fff;
            background-image: url("data:image/svg+xml,%3Csvg width='44' height='44' viewBox='0 0 44 44' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M43 22C43 33.598 33.598 43 22 43C10.402 43 1 33.598 1 22C1 10.402 10.402 1 22 1C33.598 1 43 10.402 43 22ZM44 22C44 34.1503 34.1503 44 22 44C9.84974 44 0 34.1503 0 22C0 9.84974 9.84974 0 22 0C34.1503 0 44 9.84974 44 22ZM30 22C30 26.4183 26.4183 30 22 30C17.5817 30 14 26.4183 14 22C14 17.5817 17.5817 14 22 14C26.4183 14 30 17.5817 30 22ZM32 22C32 27.5228 27.5228 32 22 32C16.4772 32 12 27.5228 12 22C12 16.4772 16.4772 12 22 12C27.5228 12 32 16.4772 32 22Z' fill='%230080C8'/%3E%3C/svg%3E%0A");
        }
    }
</style>
