/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/small/jpeg': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.667 3.333h11.072a1 1 0 01.707.293l1.207 1.207h-.008l.337.337 8.233 8.233v-.007l1.207 1.207a1 1 0 01.293.707V33.5a4 4 0 01-4 4H10.667a4 4 0 01-4-4V7.333a4 4 0 014-4zm21.548 11.625h-8.733a1 1 0 01-1-1V4.833H10.667a2.5 2.5 0 00-2.5 2.5V33.5a2.5 2.5 0 002.5 2.5h19.048a2.5 2.5 0 002.5-2.5V14.958zm-8.233-1.5V7.292l6.166 6.166h-6.166z" _fill="#1092DC"/><path pid="1" d="M11.443 26.234l-.067-.787.532-.03c.445-.03.668-.275.668-.735v-3.803h.96v3.795c0 .95-.493 1.46-1.478 1.53l-.615.03zm3.101-.067v-5.288h2.295c.58 0 1.028.145 1.343.435.315.29.472.695.472 1.215 0 .52-.157.928-.472 1.223-.315.29-.763.435-1.343.435h-1.335v1.98h-.96zm.96-2.738h1.185c.69 0 1.035-.3 1.035-.9 0-.595-.345-.892-1.035-.892h-1.185v1.792zm3.783 2.738v-5.288h3.517v.765H20.21V23.1h2.43v.765h-2.43v1.538h2.595v.765h-3.517zm6.86.075c-.585 0-1.08-.11-1.485-.33-.4-.22-.705-.533-.915-.938-.21-.405-.315-.885-.315-1.44 0-.56.108-1.042.323-1.447.215-.41.524-.725.93-.945.405-.225.89-.338 1.455-.338.37 0 .717.055 1.042.165.325.105.595.253.81.443l-.323.75a2.345 2.345 0 00-.72-.398 2.598 2.598 0 00-.81-.12c-.56 0-.985.163-1.274.488-.285.325-.428.792-.428 1.402 0 .615.147 1.083.442 1.403.295.32.733.48 1.313.48.165 0 .332-.013.503-.038.174-.03.347-.072.517-.127v-1.23h-1.215v-.713h2.018v2.543a3.7 3.7 0 01-.87.285c-.335.07-.668.105-.998.105z" _fill="#1092DC"/>'
  }
})
