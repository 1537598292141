<template>
    <div class="mosaic-block">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'mosaic-block'
}
</script>
