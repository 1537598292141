/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tools/refresh': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.13 5.07a7.5 7.5 0 018.173 1.627l1.902 1.901h-2.69a.75.75 0 000 1.5h4.5a.747.747 0 00.75-.75v-4.5a.75.75 0 00-1.5 0v2.69l-1.901-1.902a9 9 0 100 12.728.75.75 0 00-1.06-1.06A7.5 7.5 0 119.13 5.07z" _fill="#81899D"/>'
  }
})
