<template>
    <form @submit.prevent="submitForm">
        <z-select
            class="u-bottom-margin--s"
            :data="[
                {
                    'id': 1,
                    'selected': false,
                    'disabled': false,
                    'text': 'Заявка на&nbsp;оказание услуг по&nbsp;ПРР и&nbsp;хранению грузов'
                },
                {
                    'id': 2,
                    'selected': false,
                    'disabled': false,
                    'text': 'Обращение в&nbsp;отдел ОТиПБ'
                },
                {
                    'id': 3,
                    'selected': formData.theme === 3,
                    'disabled': false,
                    'text': 'Обращение к&nbsp;руководству'
                }
            ]"
            v-model="formData.theme"
            ref="theme"
            required
            placeholder="Тематика"
            @change="selectHandler"
        ></z-select>
        <z-input
            v-model="formData.name"
            ref="name"
            required
            name="name"
            placeholder="Ваше имя"
            class="u-bottom-margin--s"
        ></z-input>
        <z-input
            v-model="formData.organization"
            ref="organization"
            name="organization"
            placeholder="Организация"
            class="u-bottom-margin--s"
        ></z-input>
        <z-input
            v-model="formData.email"
            type="email"
            ref="email"
            required
            name="email"
            placeholder="Контактный e-mail"
            class="u-bottom-margin--s"
        ></z-input>
        <z-input
            v-model="formData.phone"
            type="tel"
            ref="phone"
            required
            name="phone"
            placeholder="Номер Вашего телефона"
            class="u-bottom-margin--s"
        ></z-input>
        <z-input-file
            name="file"
            ref="fileInput"
            placeholder="Прикрепить материалы (до 10 фото/видео/pdf/tiff-файлов общим размером не более 9 МБайт):"
            @change="onChange"
            multiple
            accept=".pdf, .mp4, .webm, .jpg, .png, .tiff, .jpeg"
            total-weight="9"
            total-files="10"
            class="u-bottom-margin--s"
        ></z-input-file>
        <z-textarea
            placeholder="Текст обращения"
            required
            name="text"
            ref="text"
            v-model="formData.text"
            class="u-bottom-margin--s"
        ></z-textarea>

        <div class="form-captcha">
            <div class="form-captcha__wrapper">
                <div class="form-captcha__captcha">
                    <div class="form-captcha__img-wrapper">
                        <img
                            class="form-captcha__img"
                            v-if="isUpdatedCapcha"
                            :src="'/api/captcha/?' + capchaSalt"
                            width="100"
                            height="50"
                            alt="Captcha"
                        />
                    </div>
                    <z-button
                        class="form-captcha__reset-btn"
                        type="button"
                        rounded
                        size="s"
                        kind="bordered"
                        only-icon
                        @click="updateCapcha"
                    >
                        <z-icon name="tools/refresh" width="28" height="28"></z-icon>
                    </z-button>
                </div>
                <z-input
                    v-model="captcha"
                    type="text"
                    name="captcha"
                    ref="captcha"
                    required
                    placeholder="Введите код с картинки"
                    class="form-captcha__input"
                ></z-input>
            </div>
        </div>
        <z-button>
            Отправить
        </z-button>
        <p class="u-top-margin--m">Нажимая кнопку «Отправить», я соглашаюсь на&nbsp;обработку данных и&nbsp;принимаю условия <z-link href="/cookies-policy/">Политики конфиденциальности</z-link> приложений сайта</p>
    </form>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { sendFeedbackForm } from '@/api/feedback-form.js'
import { sendCaptcha } from '@/api/captcha.js'
import { queryString } from '@/utils/queryString'

export default {
    name: 'feedback-form',
    data () {
        return {
            isLoading: false,
            formData: {
                theme: '',
                name: '',
                organization: '',
                email: '',
                text: '',
                file: [],
                phrase: this.captcha,
                ajax_call: 'Y'
            },
            capchaSalt: Date.now(),
            captcha: '',
            captchaImg: null,
            isUpdatedCapcha: true
        }
    },
    created () {
        this.getParams()
    },
    methods: {
        onChange (data) {
            // только для файлов, так как у них не может быть v-model
            this.formData.file = data
        },
        validateRequiredInputs () {
            const requiredFields = ['theme', 'name', 'email', 'phone', 'text']
            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })

            if (!this.$refs.fileInput.validate().value) {
                isValid = false
            }

            return {value: isValid, error: this.$refs.fileInput.validate().error}
        },
        updateCapcha () {
            this.isUpdatedCapcha = false
            this.captcha = ''
            this.capchaSalt = Date.now()
            setTimeout(() => {
                this.isUpdatedCapcha = true
            }, 500)
        },
        getParams () {
            let params = {}

            new URL(window.location).searchParams.forEach(function (val, key) {
                params[key] = val
            })

            if (params.theme === 'appeal') {
                this.formData.theme = 3
            }
        },
        selectHandler () {
            const urlParams = new URL(window.location).searchParams

            if (urlParams.get('theme') && this.formData.theme !== 3) {
                urlParams.delete('theme')
                queryString({})
            }
        },
        changeFilter () {
            // this.$emit('change-filter', this.formData)
            const params = {}
            this.formData.theme && (params.theme = this.formData.theme)

            queryString(params)
        },
        async submitForm () {
            if (this.isLoading) return

            const isValidRequiredInputs = this.validateRequiredInputs().value

            if (isValidRequiredInputs) {
                try {
                    this.isLoading = true

                    if (this.captcha === '') {
                        showNotyfications('Введите код с картинки*', { type: 'error' })
                        return
                    }

                    const data = new FormData()

                    for (const key in this.formData) {
                        if (Array.isArray(this.formData[key]) || (typeof this.formData[key] === 'object')) {
                            if(this.formData[key].length === 0) {
                                data.append(key + '[]', 'undefined')
                            } else {
                                for (let index = 0; index < this.formData[key].length; index++) {
                                    data.append(key + '[]', this.formData[key][index])
                                }
                            }
                        } else {
                            data.append(key, this.formData[key])
                        }
                    }

                    const captcha = {
                        phrase: this.captcha
                    }

                    const response = await sendCaptcha(captcha)

                    if (response.status === false) {
                        showNotyfications(response.message, { type: 'error' })
                    } else {
                        const formResponse = await sendFeedbackForm(data)

                        if (formResponse.status === 'success') {
                            this.clear()
                            this.$refs.fileInput.clear()
                            showNotyfications('Отправка успешна', { type: 'success' })
                        } else {
                            showNotyfications('Ошибка отправки', { type: 'error' })
                        }
                    }
                    this.updateCapcha()
                } catch (error) {
                    console.log(error)
                    showNotyfications('Ошибка отправки', { type: 'error' })
                } finally {
                    this.isLoading = false
                }
            } else {
                if (this.validateRequiredInputs().error === '') {
                    showNotyfications(`Заполните все обязательные поля`, { type: 'error' })
                } else {
                    showNotyfications(`${this.validateRequiredInputs().error}`, { type: 'error' })
                }
            }
        },
        clear () {
            for (let key in this.formData) {
                if (key !== 'ajax_call' && key !== 'phrase') {
                    if (key === 'file') {
                        this.formData[key] = []
                    } else {
                        this.formData[key] = ''
                    }
                }
            }
        }
    }
}
</script>
