<template>
    <ul class="timeline">
        <slot />
    </ul>
</template>

<script>
export default {
    name: 'time-line'
}
</script>

<style lang="scss">
    .timeline {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            background: url(/images/list-steps-dot.png) repeat-y center top;
            left: 21px;
            width: 3px;
            top: 0;
            bottom: 0;
        }
    }
</style>
