/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'contacts/note': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.242 1.835a2.75 2.75 0 00-2.75 2.75V5.73H2.03a.75.75 0 100 1.5h.462v3.083H2.03a.75.75 0 000 1.5h.462v3.083H2.03a.75.75 0 000 1.5h.462v1.146a2.75 2.75 0 002.75 2.75h10.896a2.75 2.75 0 002.75-2.75V4.585a2.75 2.75 0 00-2.75-2.75H5.242zm-1.25 14.562v1.146c0 .69.56 1.25 1.25 1.25h10.896c.69 0 1.25-.56 1.25-1.25V4.585c0-.69-.56-1.25-1.25-1.25H5.242c-.69 0-1.25.56-1.25 1.25V5.73h.23a.75.75 0 110 1.5h-.23v3.083h.23a.75.75 0 010 1.5h-.23v3.083h.23a.75.75 0 010 1.5h-.23zm2.386-.616c0-2.579 2.067-4.085 4.312-4.085 2.257 0 4.313 1.508 4.313 4.085a.75.75 0 01-.75.75H7.128a.75.75 0 01-.75-.75zm1.6-.75h5.425c-.32-1.141-1.377-1.835-2.713-1.835-1.328 0-2.39.694-2.712 1.835zm2.713-9.526a2.998 2.998 0 00-2.998 2.997V8.5l.715.003h-.715V8.5a2.99 2.99 0 002.98 3H10.69a2.998 2.998 0 000-5.996zm-1.57 2.998a1.57 1.57 0 111.57 1.57h-.014a1.562 1.562 0 01-1.555-1.568v-.002z" _fill="#0080C8"/>'
  }
})
